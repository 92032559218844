import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import AppView from '../components/AppView';

import '../styles/global.scss';

class ProductsPage extends React.PureComponent {
  render() {
    return (
      <Layout view="app">
        <Seo title="App" />
        <AppView />
      </Layout>
    );
  }
}

export default ProductsPage;
