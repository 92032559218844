import * as React from 'react';

import withMedia from '../../hoc/withMedia';

import BgImage from '../BgImage';
import Text from '../Text';
import { TEXT_TYPE } from '../../types/textTypes';

import './AppView.scss';

import RedBar from '../../images/app/redBar.inline.svg';
import RedBarMobile from '../../images/app/redBarMobile.inline.svg';

class AppView extends React.PureComponent<
  {
    windowWidth: number
  }> {
  render() {
    const { windowWidth } = this.props;

    const appBullets = [
      'Easily find your favorite Select products and certified dispensary partners near you.',
      'Scan Select products to check authenticity and view product details.',
      'Earn points towards exclusive rewards and discounts for every Select product you purchase.'
    ];

    return (
      <article className="AppView">
        <section className="AppHero">
          {/* <div className="AppHero--bg">
            <BgImage
              className="AppHero--bg--image"
              src='app/selectLogoBg'
              alt="background logo"
            />
          </div> */}
          <div className="AppHero--header" style={{ justifyContent: windowWidth > 768 ? 'space-around' : 'flex-start' }}>
            <div className="AppHero--header--item">
              <Text
                className="AppHero--header--item--title"
                style={{ marginTop: 30, textAlign: 'justify' }}
                size={TEXT_TYPE.h2}
                color="black"
                value="Coming Soon:"
              />
              <Text
                className="AppHero--header--item--title second-line"
                size={TEXT_TYPE.h2}
                tag="div"
                color="black"
                style={{ textAlign: 'justify' }}
                value="The Select App."
              />
              {/* {windowWidth > 768
                ? <Text
                  className="AppHero--header--item--details first-item"
                  size={TEXT_TYPE.h4}
                  tag="div"
                  color="black"
                  widows={3}
                  value="Coming soon: the Select Better mobile app."
                />
                : <>
                  <Text
                    className="AppHero--header--item--details first-item"
                    size={TEXT_TYPE.h4}
                    tag="div"
                    color="black"
                    widows={3}
                    value="Coming soon:"
                  />
                  <Text
                    className="AppHero--header--item--details"
                    size={TEXT_TYPE.h4}
                    tag="div"
                    color="black"
                    widows={3}
                    value="the Select Better mobile app."
                  />
                </>
              } */}

              <div
                className="AppHero--header--item--button-group"
              >
                <a
                  target="__blank"
                  href="https://itunes.apple.com/"
                >
                  <BgImage src='app/appStore' alt="app store" style={{ minWidth: 140 }} />
                </a>
                <a
                  target="__blank"
                  href="https://play.google.com/"
                >
                  <BgImage src='app/playStore' alt="play store" style={{ marginLeft: 15, minWidth: 160 }} />
                </a>

              </div>
            </div>
            <div className="AppHero--header--item">
              <BgImage
                className="AppHero--header--image"
                src='app/iphone-black'
                alt="app on iphone"
              />
            </div>
          </div>
        </section>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {windowWidth > 768
            ? <RedBar
              style={{
                width: '100%',
                height: '55vh',
                marginBottom: -6
              }}
            />
            : <RedBarMobile
              style={{
                width: '100%',
                height: '800px',
                marginBottom: -6
              }}
            />
          }
          <div className="AppHero--bullets-aligner">
            <div className="AppHero--bullets">
              {appBullets.map((text, i) => <div
                className="AppHero--bullet"
                key={text}
              >
                <BgImage
                  className="AppHero--bullet--image"
                  src='app/selectOnRed'
                  alt="bullet"
                />
                <Text
                  className="AppHero--bullet--text"
                  tag="p"
                  color="white"
                  widows={2}
                  value={text}
                />
              </div>)}
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default withMedia(AppView);
//<AppHero {...this.props} />
